import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hours", "timerTag" ]
  static classes = [ "tracking", "idle" ]
  static values = { duration: Number, trackTime: Boolean, startTime: String }

  connect() {
    if (this.trackTimeValue) {
      this.timeStarted = this.startTimeValue
      setInterval(() => { if (this.trackTimeValue) { this.startTime() } }, 1000);
    } else {
      this.timeStarted = null
    }
  }

  stopTime() {
    this.trackTimeValue = false
    this.timerTagTarget.classList.add(this.idleClass)
  }

  startTime() {
    const started = (new Date(this.timeStarted)).getTime()
    var current = (new Date).getTime() - started;

    var d = current  / 1000
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h : 0;
    var mDisplay = m > 0 ? m : 0;
    var sDisplay = s > 0 ? s : 0;

    this.hoursTarget.innerText = hDisplay.zeroPad() + ":" + mDisplay.zeroPad() + ":" + sDisplay.zeroPad() + " h"
    this.timerTagTarget.classList.add(this.trackingClass)
  }


}
