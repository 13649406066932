import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "avatar" ]

  avatar_update(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.avatarTarget.src = e.target.result
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }
}
