import { Application } from "@hotwired/stimulus"

const application = Application.start()

Number.prototype.zeroPad = function() {
  return ('0' + this).slice(-2);
}

// Configure Stimulus development experience
application.debug    = false
window.Stimulus      = application

export { application }
